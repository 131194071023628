<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            Botones
            <CButton
              color="primary"
              size="sm"
              class="m-1"
              @click="openCreateModal"
            >
              Agregar
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              v-show="!loading"
              hover
              striped
              :items="items"
              :fields="fields"
              :items-per-page="perPage"
              :pagination="pagination"
              index-column
              clickable-rows
              :activePage.sync="page"
            >
              <template #user="data">
                <td>{{data.item.user.name}}</td>
              </template>
              <template #telefono="data">
                <td>{{data.item.phone}}</td>
              </template>
              <template #actions="data">
                <div>
                  <CButton
                    @click="setCurrentItem(data.item, 'createEdit');createOrEdit = 'edit';"
                    color="warning"
                    size="sm"
                    class="m-1"
                  >
                    <font-awesome-icon icon="edit" />
                  </CButton>
                  <CButton
                    @click="setCurrentItem(data.item, 'delete')"
                    color="danger"
                    size="sm"
                    class="m-1"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </CButton>
                </div>
              </template>
            </CDataTable>
            <div 
              v-if="loading"
              class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
              <CSpinner
                style="width:4rem;height:4rem;"
                color="danger"
              />
              <p class="text-secondary my-2">Cargando datos ...</p>
            </div>
          </CCardBody>
        </CCard>
      </transition>


            <!--     Modal crear editar -->
        <CModal
        :closeOnBackdrop="false"
            class="modal-md"
            :title="createOrEdit == 'create' ? 'Crear Botón' : 'Editar Botón'"
            color="primary"
            :show.sync="createEditModal"
          >
            <CRow>
              <CCol col="6">
                <CInput label="Nombre" v-model="currentButton.name" />
              </CCol>
              <CCol col="6">
                <CInput type="number" label="Teléfono" v-model="currentButton.phone"></CInput>
              </CCol>

            <CCol col="6">
              <div class="d-flex">
                <CInput @keypress="searchLoader = true" class="input-search" style="margin-bottom:0 !important;" label="Usuario" v-model="search"/>
              </div>
          <CListGroup v-if="!searchLoader" style="overflow-y: scroll;height: 186px;">
            <CListGroupItem @click="selectUser(user.id)" :color="selectedUser.includes(user.id) ? 'dark' : null" href="#" v-for="user in optionsUser" :key="user.id">
              <p style="margin-bottom: 0;">{{user?.name}} - ({{user?.email}})</p>
            </CListGroupItem>
            <CListGroupItem v-if="noresults">
              <p>No se encontraron resultados</p>
            </CListGroupItem>
          </CListGroup>
          <div style="padding: 12px; height: 186px;" v-else>
            <div class="loader"></div>
          </div>


        <div
            v-if="loadingUsers"
            class="d-flex flex-column justify-content-center align-items-center"
            style="min-height: 300px"
            >
                <CSpinner style="width: 4rem; height: 4rem" color="danger" />
                <p class="text-secondary my-2">Cargando datos ...</p>
                </div>
            </CCol>
            <CCol col="6">
                <CInput type="number" label="ID Botón" v-model="currentButton.button_id"></CInput>
            </CCol>
            </CRow>

            <div slot="footer" class="w-100">
              <CButton
                class="mr-2"
                color="success"
                square
                :disabled="!currentButton.name || !currentButton.phone ||!selectedUser.length || !currentButton.button_id"
                size="sm"
                @click="createOrEdit == 'create' ? createButton() : updateButton()"
              >
                Guardar
              </CButton>
              <CButton
                color="red"
                square
                size="sm"
                @click="() => (createEditModal = !createEditModal)"
              >
                Cancelar
              </CButton>
            </div>
          </CModal>

          <!--                     Modall Eliminar -->
            <CModal
            :closeOnBackdrop="false"
            title="Eliminar Botón"
            color="primary"
            :show.sync="deleteModal"
            size="sm"
            class="modal-delete"
          >
            ¿Seguro que quieres eliminar el Botón
            <span style="font-weight: 800">{{ currentButton.name }}</span
            >?
            <div slot="footer" class="w-100">
              <CButton
                class="mr-2"
                color="primary"
                variant="outline"
                square
                @click="deleteButton()"
              >
                Eliminar
              </CButton>
              <CButton
                color="secondary"
                variant="outline"
                square
                @click="() => (deleteModal = !deleteModal)"
              >
                Cancelar
              </CButton>
            </div>
          </CModal>
    </CCol>
  </CRow>
</template>

<script>
import { getButtons, newButton, updateButton, deleteButton } from '../../api/buttons'
import { searchUserBySearch } from '../../api/users'
import _ from 'lodash/lodash'

export default {
  name: 'Botones',
  data: () => {
    return {
      items: [],
      fields: [
        { key: 'button_id', label: 'ID' },
        { key: 'name', label: 'Nombre' },
        { key: 'user', label: 'Usuario' },
        { key: 'phone', label: 'Teléfono' },
        { key: 'created_at', label: 'Creado' },
        { key: 'actions', label: 'Acciones' }
      ],
      perPage: 1000,
      page: 0,
      pagination: {
        align: 'center',
        doubleArrows: false,
        previousButtonHtml: 'prev',
        nextButtonHtml: 'next',
        pages: 9
      },
      loading: true,
      currentButton: {
        name: '',
        phone: '',
        button_id: null
    },
    createEditModal: false,
    deleteModal: false,
    createOrEdit: 'create',
    optionsUser: [],
    noresults: null,
    searchLoader: false,
    search: '',
    selectedUser: [],
    allSearchResults: [],
    loadingUsers: false
    }
  },
  mounted(){
    this.getButtons()
  },
  methods: {
    createButton() {
        const data = {...this.currentButton}
        data.user_id = this.selectedUser[0]
        data.button_id = +data.button_id
        
        newButton(data).then(res => {
            this.currentButton = {
                name: '',
                phone: '',
                button_id: null
            }
            this.optionsUser = []
            this.selectedUser = []
            this.createEditModal = false
            this.getButtons()
        })
    },
    setCurrentItem(item, modal) {
        this.currentButton = {
          name: item.name,
          phone: item.phone,
          button_id: item.button_id,
          id: item.id
        };
        this.allSearchResults.push({...item.user})
        this.optionsUser = [{...item.user}]
        this.selectedUser = [item.user_id]
      //qué modal se abre
      modal == "createEdit" ? (this.createEditModal = !this.createEditModal) : (this.deleteModal = !this.deleteModal);
    },
    updateButton() {
        const data = {...this.currentButton}
        data.user_id = this.selectedUser[0]
        data._method = 'put'
        data.button_id = +data.button_id

        updateButton(data).then(res => {
            this.createEditModal = false
            this.getButtons()
        })
    },
    deleteButton() {
        deleteButton({ id: this.currentButton.id }).then((res) => {
            this.deleteModal = !this.deleteModal;
            this.getButtons();
        });
    },
    searchUsers() {
      if (this.search.length > 2) {
        const data = {search: this.search};
      searchUserBySearch(data).then(res => {
        this.optionsUser = res.users
        const allIds = this.allSearchResults.map(user => user.id)

        res.users.forEach(user => {
          if (!allIds.includes(user.id)) {
            this.allSearchResults.push({...user})
          }
        })
        this.searchLoader = false
        this.noresults = !res.users.length ? true : false
      })
      } else {
        this.searchLoader = false
      }
    },
    getButtons(){
      const data = {
        limit: this.perPage,
        page: this.page
      }
      const self = this
      this.loading = true
      getButtons(data)
        .then(function(resp){
          self.items = resp.data
          self.loading = false
          // resp.data.map(function(item){
          //   self.items.push(item)
          // })
          self.pagination = {
            align: 'center',
            doubleArrows: true,
            previousButtonHtml: 'prev',
            nextButtonHtml: 'next',
            pages: Math.round(resp.total / self.perPage)
          }
          console.log(self.items)
        })
        .catch(function(error){
          console.log(error)
        })
    },
    selectUser(id) {
        this.selectedUser = [id]
    },
    openCreateModal() {
      this.createEditModal = !this.createEditModal;
      this.createOrEdit = 'create';
      this.currentButton = {
        name: '',
        phone: '',
        button_id: null
      }
      this.optionsUser = []
      this.selectedUser = []
    }
  },
    watch: {
        search: _.debounce(function (value) {
            this.searchUsers()
        }, 1000),
    },
}
</script>
<style lang="scss" scoped>
.loader {
  width: 20px;
  aspect-ratio: 1;
  display:grid;
  -webkit-mask: conic-gradient(from 15deg,#0000,#000);
  animation: l26 1s infinite steps(12);
}
.loader,
.loader:before,
.loader:after{
  background:
    radial-gradient(closest-side at 50% 12.5%,
     red 96%,#97440000) 50% 0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%,
     red 96%,#aa310100) 0 50%/80% 20% repeat-x;
}
.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.loader:after {
  transform: rotate(60deg);
}

@keyframes l26 {
  100% {transform:rotate(1turn)}
}
</style>